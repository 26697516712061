

 .bold-text{
    font-weight: 600;
    font-size: 16px;
 }

  .button{
       border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    height: 50px;;
    }

 