
.status-card-header {
    background-color: #DCDBDD;
    border-radius: 12px 12px 0px 0px;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 100px;
}

.status-card-body {
    background-color: #f6fafe;
    box-shadow: 6px 6px 20px rgba(32, 32, 35, 0.1);
    padding: 15px;
    color: black;
    border-radius: 0px 0px 12px 12px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
    gap:10px;
    overflow-y: auto;
    overflow-x: clip;
}

.status-card-title{
    font-weight: 600;
}

.learn-more{
text-decoration: underline;
cursor: pointer;
}


.link-text{
    color: #096DD9;
    text-decoration: none;
    cursor: pointer;
  }
 
  .card-wrapper {
    padding-bottom: 20px;
 }

 .status-title {
     font-size: 14px;
 } 