.en-orders-table-card {
	border-radius: 12px;
	margin-top: 20px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751);
}

.en-search-box {
	width: 340px;
	border-radius: 8px;
}

.page-container {
	padding: 20px;
}
.header {
	width: 100%;
	position: relative;
	margin: 10px 5px;
	font-size: 30px;
}
.enquire-button {
	right: 0px;
	position: absolute;
	height: 40px;
}
.empty-holder {
	display: grid;
	place-items: center;
	height: 60vh;
	text-align: center;
}

@media (max-width: 1180px) {
	.enquire-button {
		right: 0px;
		position: absolute;
		width: 100%;
	}
	.en-search-box {
		width: 100%;
		border-radius: 8px;
	}
	.header {
		margin-bottom: 20px;
	}

	.page-container {
		padding: 10px;
	}
}
