.details-card{
}

.details-card-header{
    background-color: #096DD9;
    border-radius: 12px 12px 0px 0px;
    padding: 15px;
    color: white;
}

.details-card-body{
    background-color: #F6FAFE;
    box-shadow: 6px 6px 20px rgba(32, 32, 35, 0.1);
    padding: 15px;
    color: black;
    border-radius:  0px 0px 12px 12px;
    display: grid;
    grid-template-columns:  1fr;
}

.details-card-content-bold{
font-size: 18px;
font-weight: 500;


}

.details-card-content{
    font-size: 16px;
    font-weight: normal;
    color: #595959;
    
    }

    input[type='button']{
        font-size: 14px;
        padding: 3px 20px;
        border: 1px solid #262626;
        box-sizing: border-box;
        border-radius: 8px;
        background: #FFFFFF;
        cursor: pointer;
    }

 