.en-profile-top-card
    min-width: 510px
    min-height: 245px
    border-radius: 12px
    margin: 10px
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751)

.en-profile-bottom-card
    border-radius: 12px
    width: 100%
    margin: 10px
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751)

.en-profile-picture-wrap
    display: inline-block

.en-profile-details-wrap
    display: inline-block
    min-width: 350px
    position: relative
    left: 30px
    bottom: 20px

.en-profile-pic-edit-wrap
    background: #8C8C8B
    width: 26px
    height: 26px
    border-radius: 50px
    position: relative
    bottom: 30px
    left: 80px

.en-profile-details-content
    padding: 10px

.simple-icon-placement
    position: relative
    bottom: 3px
