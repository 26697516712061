.project-card-container
    display: inline-block

.inactive-collapsed-card
    background: #FFFFFF
    box-shadow: 6px 6px 20px rgba(32, 32, 35, 0.1)
    border-radius: 12px
    margin: 20px

.card-collapsed
    margin: 20px
    border-radius: 12px
    box-shadow: 6px 6px 20px rgba(32, 32, 35, 0.1)

.card-expanded
    background: #F6FAFE
    box-shadow: 6px 6px 20px rgba(32, 32, 35, 0.1)
    border-radius: 12px
    margin: 20px

.active-card-header
    height: 57.82px
    background: #096DD9

.card-header-content
    padding: 15px

.card-content
    padding: 30px
    font-size: 14px

.number-box
    background: #F0F0F0
    width: 22.34px
    height: 22.34px
    border-radius: 2px
    text-align: center
    font-size: 14px
    color: #096DD9
    display: inline-block

.point-number-box
    background: #F0F0F0
    width: 28.34px
    height: 30px
    border-radius: 2px
    text-align: center
    font-size: 14px
    color: #096DD9
    display: inline-block

.heading-active
    display: inline-block
    font-size: 16px
    line-height: 24px
    padding-left: 15px
    color: #FFFFFF

.heading-inactive
    display: inline-block
    font-size: 16px
    line-height: 24px
    padding-left: 15px
    color: #8C8C8C

.subHeading
    padding-left: 10px

.btn
    float: right
    display: inline-block

.modal-content
    margin: 20px
    text-align: center

.line-2
    font-size: 16px
    color: #595959
    padding-bottom: 15px

.line-3
    font-size: 14px
    color: #595959
    padding: 20px
    margin-bottom: 25px

.view-project-details
    background: #F6FAFE
    box-shadow: 6px 6px 20px rgba(32, 32, 35, 0.1)
    border-radius: 12px
    margin: 20px

.pilot-card
    width: 350px
    height: 175px
    box-shadow: 10px 10px 40px rgba(32, 32, 35, 0.1)
    border-radius: 4px

.pilot-card-wrapper
    padding: 20px

.drawer-content
    background-color: #fafafa
    width: 108%
    position: relative
    right: 4%
    top: 7%
    padding: 25px
    margin-bottom: 20px
    background-color: #fafafa

.sender-box
    float: right
    padding-left: 330px

.sender
    display: inline-block
    padding: 10px
    float: left

.sender-bubble
    background: #788998
    border-radius: 20px 0px 20px 20px
    text-align: left
    font-size: 12px
    color: white
    padding: 10px
    display: inline-block

.receiver
    display: inline-block
    padding: 10px

.receiver-bubble
    background: #096DD9
    border-radius: 0px 20px 20px 20px
    text-align: center
    font-size: 12px
    color: white
    padding: 10px
    display: inline-block

.chatbox-footer
    display: flex
    width: 450px
    // margin-bottom: -10px
    position: fixed
    bottom: 10px

.message-block
    padding: 10px

.message-input
    width: calc(100% - 90px)
    margin-right: 15px

.project-type-select
    background: #FFFFFF
    box-shadow: 0px 1.23654px 4.94617px rgba(0, 0, 0, 0.09)
    border-radius: 4px
    width: 280px
    padding: 10px
    margin: 10px

.project-type-text
    padding: 8px
    display: inline-block

.filter-card
    padding: 25px

.step-description
    font-size: 12px
    text-align: left

.des-btn
    padding-top: 5px

.showCol
    display: inline

.hideCol
    display: none

.bid-history-card-header
    background: #F2F2F3
    border-radius: 12px
    display: flex
    justify-content: space-between

@media screen and ( min-width:  1180px)
    .create-new-project-form-mobile
        display: none
    .mobile-desc-holder
        display: none
    .create-new-project-nxt-btn
        height: 48px
        width: 25%
        font-size: 16px
        border-radius: 8px
        margin-top: 20px
    .bid-card-holder
        display: flex
        justify-content: space-between
    .bid-card-header
        background: #F2F2F3
        border-radius: 12px
        display: flex
        justify-content: space-between
    .cnp-budget-input
        border-radius: 8px
        height: 48px
        width: 160px
    .cnp-excess-budget-box
        margin-top: 25px
        font-size: 12px
        color: rgb(132, 129, 138)
        background: #ECEAEE
        width: 65%
        padding: 10px
        border-radius: 8px
    .cnp-subhead-box
        font-size: 16px
        color: #aba8b0
        margin-bottom: 20px
        width: 60%
    .cnp-budget-checbox-box
        color: #aba8b0
        margin-top: 10px
        width: 60%
        margin-left: 23px

@media screen and (max-width: 1180px)
    .create-new-project-form
        display: none
    .web-desc-holder
        display: none
    .create-new-project-nxt-btn
        height: 48px
        width: 100%
        font-size: 16px
        border-radius: 8px
        margin-top: 20px
    .bid-card-holder
        padding: 10px
        border: 1px solid #DCDBDD
        border-radius: 12px
    .bid-card-header
        display: none
    .cnp-budget-input
        border-radius: 8px
        height: 48px
        width: 50%
    .cnp-excess-budget-box
        margin-top: 25px
        font-size: 12px
        color: rgb(132, 129, 138)
        background: #ECEAEE
        width: 100%
        padding: 10px
        border-radius: 8px
    .cnp-subhead-box
        font-size: 16px
        color: #aba8b0
        margin-bottom: 20px
        width: 100%
    .cnp-budget-checbox-box
        color: #aba8b0
        margin-top: 10px
        width: 95%
        text-align: justify
        margin-left: 23px
.bid-card-first-part
    padding: 10px
.bid-card-second-part
    padding: 10px
.bid-card-third-part
    padding: 10px

.create-new-project-back-btn
    height: 48px
    width: 100%
    font-size: 16px
    border-radius: 8px
    background: #F2F2F3
    color: #00B3FF

.cnp-date-picker
    border-radius: 8px
    height: 48px
    width: 196px
    margin-right: 10px

.ant-picker-input > input:placeholder-shown::-webkit-input-placeholder
    color: #212222
    opacity: 1

.selected
    background: #00b3ff
    color: white

.attachment-wrapper-box
    font-size: 12px
    background: #f5f5f5
    border-radius: 8px
    width: fit-content
    height: 40px
    padding: 4px
    margin-left: 10px
    margin-bottom: 20px

.ant-card-bordered
    border-radius: 12px

.ant-card-head-title
    color: #747474

.more-btn
    float: right
    margin-top: 20px
    height: 40px
    border-radius: 8px
    font-size: 16px
    color: #00b3ff
    border: 1px solid #00b3ff

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
input[type=number]
    -moz-appearance: textfield

.bid-tag
    padding: 5px
    color: #84818A
    font-size: 12px
    border-radius: 8px
.modified
    border: 1px solid #84818A
    color: #84818A
.accepted
    border: 1px solid #6CBE44
    color: #6CBE44
.rejected
    border: 1px solid #F75151
    color: #F75151
.project-status
    border: 1px solid #FFAB2A
    color: #FFAB2A

.resend-extra-text-left
    position: relative
    left: 4px
    color: #84818A
.resend-extra-text-right
    position: relative
    right: 4px
    color: #84818A

.modal-footer
    display: flex
    justify-content: space-between

.ant-checkbox-inner
    border-radius: 4px !important
