.en-support-top-card-1
    min-width: 320px
    min-height: 108px
    border-radius: 12px
    display: inline-block
    margin-right: 25px

.en-support-top-card-2
    min-width: 320px
    min-height: 108px
    border-radius: 12px
    display: inline-block
    margin-right: 25px

.en-raise-ticket-elem-1
    margin-right: 10px

.en-raise-ticket-elem-2
    margin-right: 10px
    position: relative
    bottom: 10px

.en-raise-ticket-btn
    position: relative
    left: 45px
    bottom: 10px

.en-ac-manager-elem-2
    position: relative
    bottom: 10px

.en-ac-manager-elem-3
    position: relative
    left: 52px
    bottom: 20px

.en-ac-manager-elem-4
    position: relative
    right: 30px

.en-faq-item-wrapper
    border-radius: 4px
    margin-bottom: 10px

.page-container
    padding: 20px
