@import '../../../node_modules/antd/dist/antd.css'

.flex-container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    color: #262626
    padding: 15px 10px

.parent-card
    margin-bottom: 10px
    width: 100%
    border-radius: 8px

.child-card
    margin-bottom: 10px
    width: 100%
    border-radius: 8px
    background: #F9F9F9

.circular
    width: 100px
    height: 100px
    border-radius: 50%
    position: relative
    overflow: hidden

    & img
        min-width: 100%
        min-height: 100%
        width: auto
        height: auto
        position: absolute
        left: 50%
        top: 50%
        -webkit-transform: translate(-50%, -50%)
        -moz-transform: translate(-50%, -50%)
        -ms-transform: translate(-50%, -50%)
        transform: translate(-50%, -50%)

.thumbnail-wrapper
    width: 130px
    height: 130px
    position: relative
    overflow: hidden
    display: inline-block
    border-radius: 8px
    margin: 5px

.upload-btn
    width: 130px
    position: relative
    overflow: hidden
    display: inline-block
    margin: 5px
    bottom: 4px

.info
    color: #8c8c8c
    font-weight: normal
    position: relative
    top: -4px
    left: 5px

.enterprise-pilot-eligiblity-alert
    background-color: #096DD9
    border-radius: 10px
    color: white
    box-shadow: 0px 2px 4px rgba(138, 149, 158, 0.15)

.enterprise-pilot-eligiblity-alert-failed
    background-color: #FA541C
    border-radius: 10px
    color: white
    box-shadow: 0px 2px 4px rgba(138, 149, 158, 0.15)

.remind-me-later
    font-size: 14px
    position: relative
    float: right
    right: 60px
    top: -15px
    cursor: pointer

.en-onboarding-elem
    padding: 10px

.project-details-content
    margin: 25px
    max-width: 75%
    display: inline-block

.profile-pic-edit-wrap
    background: #00B3FF
    width: 26px
    height: 26px
    border-radius: 50px
    position: relative
    bottom: 30px

.verification-state
    width: 160px
    padding: 5px 20px 5px 20px
    color: white
    font-size: 12px
    border-radius: 8px

.ant-select-selector
    border-radius: 8px !important
.ant-picker-large
    border-radius: 8px !important

.nz-license-box-wrap
    font-size: 12px
    color: #84818A
    background: #ECEAEE
    width: fit-content
    padding: 10px
    border-radius: 8px

.nz-quiz-option-wrap
    font-size: 12px
    background: #F5F5F5
    padding: 10px
    margin-bottom: 10px
    border-radius: 8px

.ant-upload.ant-upload-select
    width: 100% !important
.ant-input-textarea-show-count > .ant-input
    border-radius: 8px
@media screen and ( min-width:  1180px)
    .data-security-btn
        margin: 10px
        float: right
        position: relative
        bottom: 55px
    .submod-card-header
        display: flex
        justify-content: space-between

@media screen and (max-width: 1180px)
    .data-security-btn
        margin: 10px
    .submod-card-header-btn
        margin-top: 10px

.insurance-banner
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05)
    border-radius: 8px
    color: white
    padding: 5px
    margin-bottom: 20px
