 

.step {
    display: grid;
    grid-template-columns: 25px minmax(50px, 1fr) minmax(250px, 1fr);
}
.step-line {
    padding-top: 5px;
    width: 100%;
}

.step-card-content {
    padding-left: 15px;
}

.step-title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}
.step-sub-title {
    font-size: 14px;
    color: #8c8c8c;
    padding-bottom: 10px;
}
.step-content-title {
    font-size: 14px;
    color: black;
    font-weight: 600;
}

.step-content {
    font-size: 14px;
    color: black;
}
