.en-aero-credit-card
    min-width: 300px
    min-height: 265px
    border-radius: 12px
    text-align: center
    margin: 10px

.en-payment-history-card
    min-width: 740px
    min-height: 265px
    border-radius: 12px
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751)
    margin: 10px

.en-transation-history-card
    border-radius: 12px
    width: 100%

.en-aero-credit-text
    margin-top: 20px
    font-size: 16px
    color: #595959

.arrow-box
    min-width: 145px
    height: 36px
    border-radius: 2px
    background: #485465
    mix-blend-mode: normal
    opacity: 0.9
    padding: 8px
