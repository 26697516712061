.refer-card-top
    background-image: url('../../Assets/Images/refer_and_earn_banner.svg')
    Width: 1076px
    Height: 542px
    Radius: 12px

.card-head-content
    color: white
    text-align: center

.refer-card-bottom
    max-width: 800px
    background: #f6fafe
    border-radius: 4px
    box-shadow: 6px 6px 20px rgba(32, 32, 35, 0.1)
    position: relative
    bottom: 175px

.same-line
    display: inline-block

.share-line
    padding: 10px

.share-line-top
    padding: 10px
    color: #8c8c8c
