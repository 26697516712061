.copy-modal-content {
   display: grid;
   place-items: center;
}

.bold-text {
   font-weight: 600;
   font-size: 20px;
}

.small-text {
   text-align: center;
}

.button {
   border-radius: 10px;
   font-size: 16px;
   padding: 10px;
   height: 50px;
   width: 170px  !important;;
   margin: 10px 35px;
}

.button-white {
   border-radius: 10px;
   font-size: 16px;
   padding: 10px;
   height: 50px;
   width: 170px !important;;
   background-color: white;
   color: black;
}

 
