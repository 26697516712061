.modal-wrapper {
	text-align: center;

	.header {
		font-size: 20px;
	}

	.header-info {
		padding: 5px;
		font-size: 16px;
		color: #595959;
	}
	.body {
		padding: 10px;
	}
	.button {
		width: 169px;
		border-radius: 8px;
	}
	.upload-button {
		width: 450px;
	}
	.upload-button-flex {
		width: 100%;
	}
}

.player-wrapper {
	padding-top: 15px;
}
