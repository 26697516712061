.outerFrame {
    margin: 0;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: white;

    .header {
        height: fit-content;
        margin: 10px 5px;
        border-bottom: 2px #eceaee solid;

        @media screen and (max-width: 980px) {
            display: none;
        }
    }

    .content {
        display: grid;
        place-items: center;
        position: relative;
        .back-btn {
            position: absolute;
            top: 0px;
            left: 10px;
            cursor: pointer;
        }
        .content-wrapper {
            max-width: 700px;
            display: grid;
            place-items: center;
            padding-top: 30px;
            .content-header {
                font-family: Red Hat Display;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #212222;
                margin: 20px 10px;
            }
            .content-text {
                font-family: Red Hat Display;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: center;
                color: #84818a;
                margin: 20px 10px;
            }
            .content-form {
                margin: 10px;
                width: 410px;

                .back-button {
                    height: 48px;
                    font-size: 16px;
                    width: 100%;
                    background: rgb(209 209 209 / 49%);
                    color: deepskyblue;
                    border-color: rgb(167 165 165 / 0%);
                }
                .continue-button {
                    height: 48px;
                    font-size: 16px;
                    width: 100%;
                    background-color: #00B3FF;
                    color: white;
                }
                .content-resend-button {
                    margin-top: -15px;
                    margin-bottom: 10px;
                    color: #00B3FF;
                   
                }
                .content-resend-text {
                    margin-top: -15px;
                    margin-bottom: 10px;
                                      
                }
            }
            .content-link-button {
                margin: 0px;
                padding: 0px;
                font-size: large;
            }
        }
    }
}


.modal-buttons {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    .back-button {
        height: 48px;
        font-size: 16px;
        width: 100%;
        background: rgb(255 255 255);
        color: deepskyblue;
        border-color: deepskyblue;
    }
    margin-top: 20px;
}