.submit-modal-content{
   display: grid;
    place-items: center;
    text-align: center;
}
 
 .bold-text{
    font-weight: 600;
    font-size: 20px;
 }

 

  .button{
       border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    height: 50px;;
    width:170px !important;
    margin: 10px 35px;
    }

    .button-white{
      border-radius: 10px;
   font-size: 16px;
   padding: 10px;
   height: 50px;
   width:170px !important;
   background-color: white;
   color: black;
   }
   

 