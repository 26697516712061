 .modal-wrapper {
   text-align: center;

   .header {
      font-size: 20px;
   }

   .header-info {
      padding: 5px;
      font-size: 16px;
      color: #595959;
      text-align: left;
   }
   .body {
      padding: 10px;
   }
   .button {
      width: 169px;
      border-radius: 8px;
   }
   .upload-button {
      width: 450px;
   }
   .exclamation-icon {
      width: 100px;
   }
}

.dragger {
   height: 285px;
   text-align: center;
   margin-top: 29px;
   padding: 50px;
   background: #fafafa;
   border: 2px dotted #979797;
   border-radius: 12px;
}
.upload-icon {
   color: #096dd9;
   font-size: 70px;
}

.header {
   font-size: 20px;
   font-weight: 600;
}

.header-info {
   padding: 5px;
   font-size: 16px;
   color: #595959;
}

.upload-ready {
   height: 50px;
   text-align: center;
   margin-bottom: 29px;

   background: #fafafa;
   border: 2px dotted #979797;
   border-radius: 12px;

   display: grid;
   grid-template-columns: 90% 10%;
   width: 95%;
}

.upload-button {
   width: 100%;
   border-radius: 8px;
   height: 40px;
}
.upload-name {
   padding: 0px 10px;
   text-align: left;
   align-self: center;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;

   span {
      vertical-align: text-bottom;
   }
}

.progress-right {
   align-self: center;
}
 
.progress-close {
   cursor: pointer;
}

.notification {
   border-radius: 11px;
   border-left: 10px solid #096dd9;
   padding: 14px 11px;
   font-size-adjust: 60%;

   .anticon {
      vertical-align: 0px;
   }
}

.upload-files-scroller {
   max-height: 300px;
   overflow-y: auto;
   margin-top: 25px;
}

/* width */
::-webkit-scrollbar {
   width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #555;
}
