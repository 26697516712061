@import '../../../node_modules/antd/dist/antd.css'

.flex-container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    color: #262626
    padding: 15px 10px
    border-radius: 4px !important
    