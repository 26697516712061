.chat-box {
	box-sizing: border-box;

	position: fixed;
	width: 300px;
	height: 400px;
	right: 20px;
	bottom: 0px;

	background: #ffffff;

	border: 1px solid #aba8b0;
	border-radius: 12px 12px 0px 0px;

	grid-template-rows: auto 1fr auto;
	display: grid;

	/* width */
	::-webkit-scrollbar {
		width: 5px;
		right: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #ffffff;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #e8e8e8;
		border-radius: 5px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgb(140, 140, 140);
	}

	.header {
		border-bottom: 1px solid #aba8b0;
	}

	.header-top {
		display: none;

		.header-top-content {
			text-align: center;
			align-self: center;
			margin-left: -60px;
		}
	}
	.header-content {
		grid-template-columns: 40px 1fr;
		display: grid;

		.avatar {
			height: 30px;
			width: 30px;
			margin: 6px;
			border-radius: 50%;
		}

		.details {
			grid-template-rows: 30px 1fr;
			display: grid;

			.top {
				padding: 5px;
				font-size: 14px;
				font-weight: 500;
				grid-template-columns: 1fr 50px;
				display: grid;

				.icons {
					display: flex;
					flex-direction: row-reverse;
					gap: 5px;
				}
				.icon {
					cursor: pointer;
					height: 16px;
					width: 20px;
				}
				.icon-max-min {
					display: block;
				}
			}
			.bottom {
				grid-template-rows: auto auto;
				display: grid;
				font-size: 12px;
				color: #47464a;
				padding: 0px 5px;
			}
		}
	}

	.body {
		margin: 0px 5px;
		overflow-y: scroll;

		.message {
			grid-template-columns: 40px 1fr;
			display: grid;

			.avatar {
				height: 30px;
				width: 30px;
				margin: 6px;
				border-radius: 50%;
			}
			.message-details {
				grid-template-rows: minmax(30px, auto) 1fr;
				display: grid;

				.top {
					padding: 5px;
					font-size: 14px;
					font-weight: 500;
				}
				.time {
					font-weight: 400;
					font-size: 10px;
					line-height: 18px;

					color: #aba8b0;
				}

				.bottom {
					font-size: 12px;
					color: #47464a;
					padding: 0px 5px;
				}
			}
		}
		.unread-messages-bar {
			position: absolute;
			bottom: 130px;
			padding: 10px;
			z-index: 5;
			left: 30%;
			color: white;
			background: #00b3ff;
			border-radius: 12px;
			font-size: 12px;
			cursor: pointer;
		}
	}

	.footer {
		grid-template-rows: auto auto;
		display: grid;
		font-size: 12px;
		padding: 3px;
		gap: 5px;

		.text-box-holder {
			padding: 0px 5px;
			.text-box {
				background-color: #f2f2f3;
				width: 100%;
				border-radius: 12px;
				height: 42px;
			}
		}
		.button-holder {
			display: flex;
			flex-direction: row-reverse;
			margin-right: 10px;
			align-self: center;
			.button {
				background-color: #00b3ff;
				width: 70px;
				border: none;
				cursor: pointer;
			}
		}
	}
	.chat-disable-msg-box {
		background: #f97855;
		border-radius: 10px;
		color: white;
		margin: 10px;
		line-height: 19px;
		font-size: 14px;
		font-weight: 500;
		height: fit-content;
		padding: 15px;
	}
}

@media screen and (max-width: 1180px) {
	.chat-box {
		grid-template-rows: auto 1fr auto;
		height: 100%;
		width: 100%;
		top: 0px;
		left: 0px;
		border-radius: 0px;
		.header-top {
			margin: 5px;
			grid-template-columns: 40px 1fr;
			display: grid;
			gap: 20px;

			.header-top-content {
				margin-left: 0px;
			}
		}
		.header-content {
			.details {
				.top {
					.icon-max-min {
						display: none;
					}
				}
			}
		}
	}
}
