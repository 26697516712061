.row {
    margin-top: 20px;
}

.projects-details-card {
    margin-top: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751);
}

.empty-description {
    font-size: 20px;

    div {
        font-size: 16px;
    }
}

.search-box {
    width: 340px;
    border-radius: 8px;
}
.table {
    margin-top: 30px;
    text-align: center;
    position: relative;
    right: 70px;
}

.icon-placement{
    position: relative;
    bottom: 3px;
}

.project-small-card-wrapper {
    width: 100%;
    min-height: 197px;
    border-radius: 12px;
    background: #f9f9f9;
    box-shadow: 0px 2px 6px rgba(50, 64, 77, 0.1);
    margin: 10px;
    text-align: left;

    .name{
        font-size: 16px;
    }
    .address-wrapper {
        margin-top: 10px;
        .address {
            font-size: 12px;
            margin-left: -4;
        }
    }

    .project-type-wrapper ,.project-state-wrapper , .completed-date-wrapper,.scheduled-date-wrapper{
        font-size: 12px;
        color: #595959;
        margin-top: 10px;
        .project-type ,.project-state, .completed-date,.scheduled-date {
            margin-left: 7px;
        }
    }

    .download-wrapper{
        font-size: 16px;
        .link{
            font-size: 12px;
            margin-left: -10px;
        }
    }

    .progress-bar{
        text-align: center;
    }
    .progress{
        font-size: 12px;
        color: #595959;
        margin-top: 5px;
        text-align: center;
    }
    .live-stream{
        text-align: center;
        margin-top: 10px;
        .button{
            border-radius: 8px;
            width: 100%;
        }
    }
}
