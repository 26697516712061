@import '../../../node_modules/antd/dist/antd.css'

.flex-container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    color: #262626
    padding: 15px 10px

    .card
        margin-bottom: 10px
        width: 100%

        .flex-card
            display: flex
            justify-content: space-between

.main-label-text
    color: black

.speciality-box-default
    display: inline-block
    border: 1px solid black
    color: black
    border-radius: 4px
    cursor: pointer
    vertical-align: middle
    width: fit-content
    padding: 10px
    text-align: center
    margin-right: 15px
    margin-bottom: 15px

.speciality-box-active
    display: inline-block
    border: 1px solid black
    color: white
    background: black
    border-radius: 4px
    cursor: pointer
    vertical-align: middle
    width: fit-content
    padding: 10px
    text-align: center
    margin-right: 15px
    margin-bottom: 15px

.speciality-footer
    margin-left: 715px
    margin-top: 80px
    margin-bottom: 5px

.speciality-footer-create
    margin-left: 290px
    margin-top: 30px
    margin-bottom: 5px

.vacation-mode-on-tag
    display: inline-block
    color: white
    background: #FAAD14
    font-size: 14px
    vertical-align: middle
    width: fit-content
    padding: 10px
    text-align: center
    margin-left: 25px
    border-radius: 4px

.days-box-tag
    display: inline-block
    color: #595959
    background: white
    vertical-align: middle
    width: 158px
    padding: 5px
    padding-left: 10px
    text-align: left
    border-radius: 4px
    border: 1px solid #D9D9D9

.update-pref-left-item
    display: inline-block
    width: 450px
    position: relative
    bottom: 85px

.update-pref-left-item-vacation
    display: inline-block
    width: 450px
    position: relative
    bottom: 30px

.update-pref-right-item
    display: inline-block
    width: 450px
    margin-left: 50px
