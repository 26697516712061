.thumbnail-card {
    height: 116px;
    width: 116px;
    border-radius: 15px;
    background-color: #f5f5f5;
    position: relative;
}
.thumbnail-card-selected {
    height: 116px;
    width: 116px;
    border-radius: 15px;
    background-color: #f5f5f5;
    position: relative;
    border: 3px solid #096DD9;
}

.thumbnail-card-selected :hover {
    opacity: 0.7;
}

.thumbnail-card :hover {
    opacity: 0.7;
}

.thumbnail-img {
    height: 100%;
    width: 100%;
    border-radius: 13px;
}

.thumbnail-default {
    height: 42px;
    width: 42px;
}

.thumbnail-default-holder {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
}

.thumbnail-overlay-greyed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.1s ease;
    background-color: black;
    color: white;
    border-radius: 12px;
    display: grid;
    place-items: center;
    z-index: 10;
    cursor: pointer;
}

.thumbnail-overlay {
    position: absolute;

    top: 0;
    margin-top: 5px;
    margin-right: 10px;
    right: 0;

    z-index: 9;
    transition: 0.1s ease;

    background-color: white;
    border-radius: 12px;
    display: grid;
    place-items: center;
}
.thumbnail-name-holder {
    position: absolute;
    bottom: 0px;
    background-color: rgba(0 ,0, 0 , 0.6);
    color: white;
    width: 100%;
    z-index: 0;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}
.thumbnail-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    width: 105px;
}

.thumbnail-holder {
    color: white;
    cursor: pointer;
}
.thumbnail-holder:hover {
    opacity: 1;
}

a {
    text-decoration: none;
    color: white;
}
