@import '../../../node_modules/antd/dist/antd.css'
@import url('https://v1.fontapi.ir/css/SFProDisplay')
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&family=Spicy+Rice&display=swap')

.content-container
    margin: 20px
    min-height: 700px
    border-radius: 8px !important

.history-status-box-accepted
    display: flex
    flex-direction: row
    align-items: center
    box-sizing: border-box
    border-radius: 8px
    padding: 1px 8px
    width: fit-content
    font-size: 12px
    border: 1px solid #FFE189
    color: #FAAD14

.history-status-box-completed
    display: flex
    flex-direction: row
    align-items: center
    box-sizing: border-box
    border-radius: 8px
    padding: 1px 8px
    width: fit-content
    font-size: 12px
    border: 1px solid #B7EB8F
    color: #52C41A

.history-status-box-rejected
    display: flex
    flex-direction: row
    align-items: center
    box-sizing: border-box
    border-radius: 8px
    padding: 1px 8px
    width: fit-content
    font-size: 12px
    border: 1px solid #FFA39E
    color: #F5222D

.history-status-box-invalid
    display: flex
    flex-direction: row
    align-items: center
    box-sizing: border-box
    border-radius: 8px
    padding: 1px 8px
    width: fit-content
    font-size: 12px
    border: 1px solid #91D5FF
    color: #1890FF

.project-history-filter-box
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1)
    margin-top: 20px
    width: 70%
    float: right

.filter-action-btn
    border-radius: 4px
    width: 112px
    margin-left: 20px

.competition-banner
    border-radius: 10px
    margin: 20px
    background-image: url('../../Assets/Images/comp_banner_bg.svg')
    background-repeat: no-repeat
    background-size: cover

.competition-banner-getty
    border-radius: 10px
    margin-bottom: 20px
    background-image: url('../../Assets/Images/comp_banner_bg.svg')
    background-repeat: no-repeat
    background-size: cover

.competition-banner-nz
    border-radius: 10px
    margin: 20px
    background-image: url('../../Assets/Images/competition-bg-nz.svg')
    background-repeat: no-repeat
    background-size: cover

.home-heading
    font-size: 32px
    margin-bottom: 20px

.inv-web-btn-wrapper
    display: inline-block
    padding: 10px
    float: right

.inv-mobile-btn-wrapper
    padding: 10px

.filter-btn
    border-radius: 16px
    height: 56px
    width: 56px
    float: right
    font-size: 20px

.new-project-btn
    height: 48px
    width: 240px
    font-size: 16px
    border-radius: 8px

.card-divider
    width: 1px
    height: 14px
    background: #D1D1D1
    position: relative
    top: 2px
    left: 30%

.web-cards-for-projects
    padding: 10px

.mobile-cards-for-projects
    padding: 10px

.web-posted-at-text
    color: #84818A
    float: right
    font-size: 14px
    margin-top: 4px

.mobile-posted-at-text
    color: #84818a
    font-size: 12px
    margin-left: 10px

@media screen and (max-width: 1180px)
    .web-cards-for-projects
        display: none
    .web-posted-at-text
        display: none
@media screen and (min-width: 1180px)
    .mobile-cards-for-projects
        display: none
    .mobile-posted-at-text
        display: none

@media screen and ( max-width:  1180px)
    .web-home-wrapper
        display: none
    .mobile-home-wrapper
        display: block
    .home-heading
        display: none
    .inv-btn-wrapper
        float: left
    .inv-web-btn-wrapper
        display: none
    .inv-mobile-btn-wrapper
        display: block
    .filter-btn
        position: fixed
        bottom: 5%
        right: 5%
    .project-history-filter-box
        position: fixed
        bottom: 10%
    .projects-heading
        display: none
    .new-project-btn
        width: 100%

@media screen and (min-width: 1180px)
    .web-home-wrapper
        display: block
    .mobile-home-wrapper
        display: none
    .inv-btn-wrapper
        float: right
    .inv-web-btn-wrapper
        display: block
    .inv-mobile-btn-wrapper
        display: none
    .projects-heading
        font-size: 32px
    .new-project-btn
        float: right

.project-card-wrapper :hover
    background: #f2f2f3
    border-radius: 12px
    cursor: pointer

.discount-alert-box
    background: #00B3FF
    color: white
    margin: 20px
    padding: 35px
    border-radius: 12px

// ENTERPRISE PLUS PILOT BANNER
.enterprise_plus-banner
    background-color: #00B3FF
    border-radius: 10px
    margin: 20px

.enterprise_plus_main
    color: white
    margin: 15px 0
    display: flex

.enterprise_plus_data
    display: flex
    margin-left: 1rem
    font-family: 'Red Hat Display'
    flex-direction: column
    flex-grow: 1
    font-weight: 500

.enterprise_plus_data-gradiant
    background: -webkit-linear-gradient(270deg, #F67E00 0.3%, #F79F00 18.57%, #F9BB00 38.6%, #F9CF00 58.64%, #FADB00 78.68%, #FADF00 98.52%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent

.enterprise_plus_data-gradiantPlus
    background: -webkit-linear-gradient(270deg, #F67E00 0.3%, #F79F00 18.57%, #F9BB00 38.6%, #F9CF00 58.64%, #FADB00 78.68%, #FADF00 98.52%)
    color: #ffffff
    display: inline-block
    border-radius: 7px

.enterprise_plus_data-gradiantPlus_sub
    display: flex
    align-items: center
    justify-content: center
    width: 18px
    height: 18px

.enterprise_plus_banner_buttons
    display: flex
    margin-top: 1rem
    font-size: 16px
    gap: 1rem

.enterprise_plus_banner_buttons-first
    width: auto
    padding: 0.7rem 3rem 0.7rem 0
    background-color: transparent
    border: 0
    color: #fff
    display: flex
    height: auto
    font-weight: 500
    justify-content: center !important

.enterprise_plus_banner_buttons-second
    width: auto
    font-weight: 500
    padding: 0.7rem 3rem
    border: 0
    color: #00b3ff
    display: flex
    height: auto

@media screen and (max-width: 700px)
    .enterprise_plus-banner-logo
        display: none

@media screen and (max-width: 500px)
    .enterprise_plus_main
        flex-direction: column
        align-items: center
        text-align: center
    .enterprise_plus-banner-img
        width: 100px !important
        height: 60px !important
    .enterprise_plus_data-name
        font-size: 22px !important
    .enterprise_plus_banner_buttons
        flex-direction: column-reverse
        text-align: center
    .enterprise_plus_banner_buttons-first, .enterprise_plus_banner_buttons-second
        padding: 0.7rem 0
        justify-content: center

.invited-by-aero-tag
    font-size: 12px
    border: 1px solid #00B3FF
    border-radius: 8px
    padding: 5px 8px
    background: #E5F7FF
    float: right
    margin-right: 10px

.nz-home-title
    font-family: 'Spicy Rice', cursive
    font-size: 32px
    margin: 20px

.nz-home-subtitle
    font-family: "SF Pro Display"
    font-size: 16px
    margin: auto
    width: 20%
    color: #47464A
    padding: 10px

.nz-home-footer
    font-family: "SF Pro Display"
    font-size: 16px
    margin: auto
    width: 20%
    font-style: italic
    color: #47464A
    padding-top: 30px

.nz-banner-title
    font-size: 20px
    font-weight: 700
    letter-spacing: 0.05em
    font-family: 'Red Hat Display', sans-serif
    margin-left: 5px

.nz-banner-subtitle
    font-size: 16px
    margin-top: 10px
    font-weight: 600
    letter-spacing: 0.05em
    font-family: 'Red Hat Display', sans-serif

.nz-banner-footer
    margin-top: 10px
    margin-bottom: 15px
    font-weight: 400
    letter-spacing: 0.05em
    font-family: 'Red Hat Display', sans-serif
