 .video-player{
     z-index: 100;
     width: 100vw;
     height: 100vh;
     position: absolute;
     top: 0;
     left: 0;
     background-color: black;
     color: white;
 }

 .close-button{
    position: absolute;
    top: 5;
    right: 0;
    z-index: 101;
    font-size: 25px;
    font-weight: 600;
    cursor: pointer;
    border: 0px white solid;
    background-color: rgba(54, 54, 53, 0.712);
    padding: 0px 18px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius:10px;
    margin-right: 15px;
 }
 