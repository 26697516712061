
.sidebar-logo
    height: 60px
    padding: 20px 10px 0px 10px

.ant-menu-item-selected
    background-color: white !important
    border-radius: 12px
    margin: 10px
    max-width: 180px

.ant-menu-item a
    color: #84818A

.active
    color: #00B3FF !important

.mobile-sidebar-menu-selected
    background: white
    padding: 15px
    margin: 10px
    border-radius: 12px
    color: #00B3FF
    font-size: 16px
    width: 100%

.mobile-sidebar-menu
    padding: 15px
    margin: 10px
    border-radius: 12px
    color: #84818A
    font-size: 16px
    width: 100%
