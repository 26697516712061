.flex-container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    color: #262626
    padding: 15px 10px

.flex-container-row
    display: flex
    justify-content: center
    align-items: center
    justify-content: space-around
    padding-top: 100px

    .onboarding-card
        height: 215.39px
        width: 503.49px
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05)
        border-radius: 10px

    .onboarding-card-content
        padding-left: 20px
        padding-top: 30px
        font-size: 25px

    .onboarding-text
        padding-top: 50px

.login-text
    font-size: 24px
    padding-top: 30px
    padding-bottom: 20px

.label-text
    font-size: 14px
    padding-bottom: 5px

.aero-form
    display: flex
    flex-direction: column
    width: 32%

.error
    color: #cc0000
    font-size: 12px

.logo-box
    width: 258.72px
    padding-top: 35px
    padding-bottom: 30px

.status-card
    display: flex
    align-items: center
    flex-direction: column
    height: 300px
    min-width: 400px
    justify-content: space-evenly
    padding: 5px 15px
    background: #FFFFFF
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05)
    border-radius: 4px

.card-footer
    padding-top: 30px

.link-text
    font-size: 16px
    cursor: pointer
    text-decoration: underline

.right-link
    margin-right: 125px

.contact-support
    font-size: 12px
    padding-top: 30px
    cursor: pointer

.drawer-footer
    border-top: 1px solid #e9e9e9
    padding: 10px 16px
    text-align: right
// .submit-btn
//     border-radius: 4px !important
//     background-color: #262626 !important
//     font-size: 16px !important
//     color: white !important
//     width: 350px !important
//     height: 40px !important

.social-btn
    width: 225px
    height: 62px
    font-size: 16px

.social-btn-box
    padding: 35px

.referral-block
    text-align: center
    padding: 30px

.invitee-block
    background: #f5f5f5
    border-radius: 4px
    margin: 0 auto
    width: 200px
    padding: 5px

.box-wrapper
    padding-top: 25px

.fb-btn
    background: #3C5A99
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.fb-btn:hover
    background: #3C5A99
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.google-btn
    background: #DB4437
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.google-btn:hover
    background: #DB4437
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.apple-btn
    padding: 15px
    cursor: pointer

.apple-btn-mobile
    margin-top: 15px
    cursor: pointer
    background: black
    border-radius: 8px

#appleid-signin
    text-align: center

.fb-btn-up
    background: #3C5A99
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.fb-btn-up:hover
    background: #3C5A99
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.google-btn-up
    background: #DB4437
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.google-btn-up:hover
    background: #DB4437
    color: white
    border-radius: 10px
    width: 250px
    height: 50px
    font-size: 20px

.apple-btn-up
    background: black
    color: white
    border-radius: 3px
    width: 250px
    height: 50px
    font-size: 16px

.ant-btn
    border-radius: 8px
.continue-with-email-btn
    height: 48px
    font-size: 16px
    margin-top: 20px

.modal-buttons 
    display: grid 
    gap: 1rem 
    grid-template-columns: repeat(2, 1fr) 
    margin-top: 20px 
    .back-button 
        height: 48px 
        font-size: 16px 
        width: 100% 
        background: rgb(255 255 255) 
        color: deepskyblue 
        border-color: deepskyblue 

.social-buttons
    text-align: center
    margin-bottom: 20px
    place-content: center
    place-items: center
    display: grid
    gap: 20px


@media screen and (min-width: 1125px)
    .side-img
        float: left
    .top-img
        display: none
    .left-content-holder
        position: relative
        left: 25vw
        bottom: -25vh
        height: 70vh
        &:hover
            overflow: auto

    .left-content-holder-signup
        position: relative
        left: 20vw
        bottom: -2vh
        width: 35vw
        height: 95vh
        &:hover
            overflow: auto

    .continue-with-email-btn
        width: 25vw
    .left-content-wrapper
        display: inline-block
    .login-as-other-mobile
        display: none
    .ob-content-wrapper
        width: 40%
        margin: auto
        text-align: center
    .ob-content-wrapper-form
        width: 60%
        margin: auto
        text-align: center

    .social-buttons
        text-align: center
        margin-bottom: 20px
        place-content: center
        place-items: center
        display: grid
        grid-template-columns: repeat(2,250px)

@media screen and (max-width: 1125px)
    .side-img
        display: none
    .top-img
        background: #00B3FF
        height: 141px
    .left-content-holder
        padding: 10px
    .left-content-holder-signup
        padding: 10px
    .login-as-other-web
        display: none
    .continue-with-email-btn
        width: 100%
    .left-content-wrapper
        padding: 10px
    .ob-content-wrapper
        margin: auto
        width: 70%
        text-align: center
    .ob-content-wrapper-form
        padding: 20px
        margin: auto
        text-align: center

    

#appleid-signin
    cursor: pointer
