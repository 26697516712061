@import '~antd/dist/antd.css'

.app-container
    height: 100%

@media screen and ( max-width:  1180px)
    .sidebar-on
        display: none

.ant-popover-inner
    border-radius: 12px !important
