@import '../../../node_modules/antd/dist/antd.css'

.flex-container
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    color: #262626
    padding: 15px 10px

.upload-btn
    padding-top: 15px
    padding-bottom: 15px

.payment-page-alert
    background-color: #FA541C
    border-radius: 10px
    color: white
    box-shadow: 0px 2px 4px rgba(138, 149, 158, 0.15)
    width: 95%
    margin: 10px 25px

.portfolio-page-alert
    background-color: #096DD9
    border-radius: 10px
    color: white
    box-shadow: 0px 2px 4px rgba(138, 149, 158, 0.15)
    height: 73px

.digital-box
    min-width: 300px
    border-radius: 8px
    display: inline-block
    margin-right: 20px

.icon-box
    display: inline-block
    color: #E48934
    padding: 4px

.icon-box-2
    display: inline-block
    color: #E48934
    position: relative
    top: 20px

.label-box
    display: inline-block
    margin-left: 15px
    font-size: 13px

.value-box
    padding-left: 45px
    padding-top: 10px
    font-size: 28px
    color: #262626

.value-box-2
    padding-left: 50px
    font-size: 28px
    color: #262626

.filter-value
    font-size: 10px
    color: #8C8C8C

.filter-icon
    font-size: 17px
    position: relative
    top: 22px
    margin-left: 20px
    color: #00B3FF

.button-wrapper
    padding-bottom: 10px

.account-card
    display: inline-block
    border-radius: 4px
    margin: 15px
    box-shadow: 0px 0px 10px rgba(138, 149, 158, 0.15)

.payments-name-col
    width: 8%
.payments-col
    width: 15%

@media screen and ( min-width:  1180px)
    .account-card
        width: 45%

@media screen and (max-width: 1180px)
    .account-card
        width: 100%

.your-earning-box
    background: #F2F2F3
    width: fit-content
    padding: 15px
    margin: 10px
    border-radius: 12px

.ant-picker-range
    border-radius: 8px
