.metadata-card-holder {
    background-color: white;
    border-radius: 12px;
    padding: 15px;
}

.metadata-card-header {
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    border-bottom: 1px solid #e9e9e9;
    display: grid;
    grid-template-columns: 50% 50%;

    div {
        display: flex;
        flex-direction: row-reverse;
        font-weight: lighter;
        .invalid-file {
            color: white;
            font-size: 13px;
            padding: 5px;
            background-color: #fa541c;
            border-radius: 5px;
            margin-right:8px ;
        }
        .sample-data{
            color: black;
            border:black 1px solid;
            border-radius: 5px;
            font-size: 12px;
            padding: 5px;
            padding-left:10px;padding-right: 10px;
            cursor: pointer;
            height: max-content;
        }
    }
}

.metadata-card-body {
    height: 550px;
    max-height: 550px;
    overflow-y: auto;
    padding: 15px;
}

.star {
    color: red;
}

.textbox {
    border-radius: 6px;
    box-shadow: none;
    outline: none;
    width: 100%;
}

.textbox-error {
    border: 1px solid red;
}

.textbox-error:hover {
    border: 1px solid red;
}
.textbox-error:focus {
    border: 1px solid red;
}

.error-text {
    color: red;
    font-size: 12px;
    font-weight: 400;
}
.button-holder{
    display: grid;
    place-items: center;
}
.button {
    font-size: 14px;

    box-sizing: border-box;
    border-radius: 8px;

    cursor: pointer;
    width: max-content;
    margin: 5px;
}

div[role="alert"] {
    padding-top: 5px;
}

.content-bold {
    font-weight: 600;
}

.content-static {
    line-height: 42px;
}

.alert {
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    border: none;
}
.warning-alert {
    background-color: #fa541c;
}

.getty-reasons-holder{
    padding: 20px;
    color: white;
    
    border-radius: 15px;
}

.getty-reasons-revision,
.getty-reasons-rejected {
    padding: 15px;
    color: white;
    background-color: #e63c03;
    border-radius: 15px;

    hr {
        color: transparent;
    }
}

.getty-reason,
.getty-note{
    display: grid;
    grid-template-columns:   150px 1fr;
}

.getty-learn-more-holder{
    display: flex;
    flex-direction: row-reverse;
   
}

.getty-learn-more{
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
}
