.outer-card {
	margin: 20px;

	/* width */
	::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.contribution-content-holder {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	gap: 10px;
}

.contribution-content {
	background-color: white;
	border-radius: 12px;

	padding: 20px;
}

.contribution-content-scroll {
	height: 600px;
	max-height: 600px;
	overflow-y: auto;

	display: grid;

	grid-gap: 25px;
	grid-template-columns: repeat(auto-fit, 116px);
	grid-auto-rows: max-content;
}

.contribution-footer {
	margin-top: 25px;
	display: flex;
	flex-direction: row-reverse;
	width: 100%;
	background-color: white;
	border-radius: 12px;
	gap: 25px;
	padding: 20px;
}
.contribution-sample-holder {
	background-color: #096dd9;
	width: 100%;
	height: max-content;
	margin-bottom: 15px;
	border-radius: 12px;
	grid-template-columns: 1fr 100px;
	display: grid;
	padding: 10px;
	color: white;
}
.view-now-button {
	text-decoration: underline;
	cursor: pointer;
}

.button {
	font-size: 14px;

	box-sizing: border-box;
	border-radius: 8px;

	cursor: pointer;
	padding: 0px 25px;
}

.disabled-button {
	background-color: #bfbfbf;
	color: white;
	cursor: not-allowed;

	font-size: 14px;

	box-sizing: border-box;
	border-radius: 8px;
	padding: 0px 25px;
	border: none;
}
.disabled-button:hover {
	background-color: #bfbfbf;
	color: white;
	border: none;
}
.disabled-button:focus {
	background-color: #bfbfbf;
	color: white;
	border: none;
}

.alert {
	text-align: center;
	padding: 10px;
	border-radius: 10px;
	border: none;
}
.warning-alert {
	background-color: #fa541c;
}
.error-alert {
	background-color: #ff4d4f;
}

.ant-alert-close-icon .anticon-close {
	color: rgb(255, 255, 255);
	transition: color 0.3s;
}

.thumbnail-card {
	height: 116px;
	width: 116px;
	border-radius: 15px;
	background-color: #f5f5f5;
	line-height: 30px;
	display: grid;
	place-items: center;
	color: #595959;
	border: 1px dashed #d9d9d9;
	cursor: pointer;
	.plus {
		color: #8c8c8c;
		font-size: 40px;
		text-align: center;
	}
}

.notification {
	border-radius: 11px;
	border-left: 10px solid #096dd9;
	padding: 14px 11px;
	font-size-adjust: 60%;

	.anticon {
		vertical-align: 0px;
	}
}
