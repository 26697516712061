 
 .link-text{
   color: #096DD9;
   text-decoration: none;
 }

  .button{
       border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    height: 50px;;
    }

 