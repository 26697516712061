@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700;800;900&display=swap');

// FOR STEPPER
.main {
	display: flex;
	flex-direction: column;
	padding: 40px 0 0 0;

	.handshake_img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		align-self: center;
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
		font-family: 'Red Hat Display';

		h2 {
			text-align: center;
			font-weight: 600;

			@media screen and (max-width: 400px) {
				font-size: 1.25rem !important;
			}

			span {
				background: -webkit-linear-gradient(270deg, #f67e00 0.3%, #f79f00 18.57%, #f9bb00 38.6%, #f9cf00 58.64%, #fadb00 78.68%, #fadf00 98.52%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.header_plus_sign {
				background: -webkit-linear-gradient(270deg, #f67e00 0.3%, #f79f00 18.57%, #f9bb00 38.6%, #f9cf00 58.64%, #fadb00 78.68%, #fadf00 98.52%);
				color: #ffffff;
				display: inline-block;
				border-radius: 7px;

				div {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 18px;
					height: 18px;
				}
			}
		}

		p {
			text-align: center;
			padding: 0 25px;
			color: #47464a;
			line-height: 24px;
			font-weight: 400;
		}
	}

	.button_parent {
		text-align: center;
		padding: 20px;

		button {
			height: 50px;
			border-radius: 8px;
			width: 90%;
			font-family: 'Red Hat Display';
			font-weight: 500;
		}
	}
}

.events {
	margin: 1em auto;

	&::before,
	&::after {
		box-sizing: border-box;
		font-family: arial;
	}

	li {
		display: flex;
		color: #999;
		position: relative;
		padding: 0 0.9em;

		@media (max-width: 450px) {
			padding: 0 0.8em;
		}

		span {
			padding: 0 1.5em 1.5em 1.5em;
			position: relative;
			font-family: 'Red Hat Display';
			font-weight: 500;
			font-size: 14px;
			color: #111111;

			@media (max-width: 450px) {
				font-size: 12px;
			}

			strong {
				display: block;
				color: #84818a;
				font-family: 'Red Hat Display';
				font-weight: 500;
				font-size: 12px;
			}
		}
	}
}

.events li:nth-child(odd) {
	flex-direction: row-reverse;
	left: -60%;
	text-align: right;

	&::before {
		content: '';
		position: absolute;
		z-index: 3;
		right: 10px;
		top: 3px;
		// transform: translateX(100%);
		border-radius: 50%;
		background-color: #80d9ff;
		width: 0.5em;
		height: 0.5em;

		@media (max-width: 450px) {
			right: 8px;
		}
	}
	&::after {
		content: '';
		position: absolute;
		z-index: 2;
		right: 13px;
		top: 0;
		transform: translateX(50%);
		border-radius: 50%;
		background: #fff;
		border: 1px #80d9ff dotted;
		width: 1em;
		height: 1em;

		@media (max-width: 450px) {
			right: 11px;
		}
	}
}

.events li:nth-child(even) {
	flex-direction: row;
	left: 28%;

	&::before {
		content: '';
		position: absolute;
		z-index: 3;
		left: 10.5px;
		top: 3px;
		border-radius: 50%;
		background-color: #80d9ff;
		width: 0.5em;
		height: 0.5em;

		@media (max-width: 450px) {
			left: 9px;
		}
	}
	&::after {
		content: '';
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		transform: translateX(50%);
		border-radius: 50%;
		background: #fff;
		border: 1px #80d9ff dotted;
		width: 1em;
		height: 1em;

		@media (max-width: 450px) {
			left: -1.5px;
		}
	}
}

.events li:nth-child(even) span::before {
	content: '';
	position: absolute;
	z-index: 1;
	left: 0;
	height: 100%;
	border-left: 1px #ccc dotted;
}

.events li:nth-child(odd) span::before {
	content: '';
	position: absolute;
	z-index: 1;
	right: 0;
	height: 100%;
	border-left: 1px #ccc dotted;
}

ul li + li + li + li span::before {
	display: none;
}
// *****************************

// FOR TERMS AND CONDITIONS
.terms_and_conditions {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #ffffff;
	font-family: 'Red Hat Display';
	overflow-x: hidden;

	.goback {
		position: relative;
		top: 50px;
		left: 50px;
		font-weight: 600;
		font-size: 24px;
		display: flex;
		align-items: center;

		.goback_button {
			height: auto;
		}
	}

	.heading_main {
		display: flex;
		justify-content: space-evenly;

		.heading_main_name {
			text-align: center;
			width: 95%;
			font-weight: 700;
			font-size: 20px;
		}

		.heading_main_buttons {
			width: 5%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.heading {
		margin: 5rem 5rem 0rem 5rem;

		@media (max-width: 700px) {
			margin: 5rem 0.5rem 0rem 0.5rem !important;
		}
	}

	.terms_and_conditions-text {
		border: 1px solid #eceaee;
		border-radius: 12px;
		padding: 1.5rem;
		margin: 3rem 5rem;
		height: 500px !important;
		overflow-y: scroll;
		overflow-x: hidden;
		font-weight: 600;
		font-size: 16px;

		.mainTitle {
			font-size: 16px;
			font-weight: 700;
			width: 100%;
		}

		.mainTitleDescription {
			font-size: 14px;
			width: 100%;
			margin-top: 10px;
			// line-height: 1.5px;
		}

		.subTitle {
			font-size: 14px;
			margin: 12px 0 0 0;
			font-weight: 700;
		}

		.subTitleDescription {
			font-size: 14px;
			width: 100%;
			margin-top: 10px;
		}

		.children {
			font-size: 14px;
			display: flex;
			margin-top: 10px;
			font-weight: 700;
			gap: 40px;
		}

		.childrenIndexData {
			display: flex;
			margin-top: 18px;
			gap: 20px;
			font-size: 14px;
			flex: 1;

			.childrenIntegerIndex {
				width: 30px;
				max-width: 100%;
			}
		}

		.subChildrenIndexData {
			font-size: 13px;
			display: flex;
			margin: 10px 0 0 50px;

			.subChildrenIntegerIndex {
				// margin-right: 30px;
				width: 30px;
			}
		}

		.document_dynamic_content {
			display: flex;
			justify-content: space-between;
			margin-top: 2rem;

			.document_images {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 0.5rem;
				font-weight: 500;

				.image {
					width: 250px;
					height: 100px;
					padding: 0.5rem;
					border: 1px solid #eceaee;
					border-radius: 12px;
					object-fit: contain;
					display: grid;
					place-items: center;
				}

				.imageContainer {
					width: 250px;
					height: 100px;
					border: 1px solid #eceaee;
					border-radius: 12px;
					display: grid;
					place-items: center;

					.signImagePreview {
						padding: 0.5rem;
					}
				}
			}

			@media screen and (max-width: 980px) {
				flex-direction: column !important;
				gap: 1.5rem;

				.image,
				.imageContainer {
					width: 100% !important;
					height: 100px !important;
				}
			}
		}

		@media (max-width: 700px) {
			margin: 3rem 0.5rem !important;
		}
	}

	.submit_button {
		margin: 0rem 10rem 0rem 10rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;

		@media (max-width: 700px) {
			margin: 1rem 3rem 0rem 3rem !important;
		}

		.checkbox {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}

		.next_eSign_button {
			width: 200px;
			height: 50px;
			font-weight: 500;
			font-size: 16px;
			margin-bottom: 3rem;
		}
	}

	.sub_button {
		padding: 0 8px;
	}
}
// *****************************

// REGISTRATION SUCCESSFULL MODAL
.registration_sucess {
	display: flex;
	flex-direction: column;
	padding: 40px 0 0 0;

	.registration_sucess_img {
		width: 100px;
		height: 100px;
		object-fit: cover;
		align-self: center;
	}

	.registration_sucess_header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
		font-family: 'Red Hat Display';

		h2 {
			text-align: center;
			font-weight: 600;
		}

		p {
			text-align: center;
			padding: 0 25px;
			color: #47464a;
			line-height: 24px;
			font-weight: 400;
			font-size: 16px;
		}
	}
}

// *****************************

// SIGNATURE MODAL
.eSign_main {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 0 0 0;
	font-family: 'Red Hat Display';
	gap: 1rem;

	.eSign_preview {
		width: 100%;
		height: 200px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			border: 1px solid #eceaee;
			border-radius: 12px;
		}
	}

	.eSign_button {
		width: 150px;
	}

	.eSign_submit {
		width: 100%;
		text-align: center;
		padding: 20px;

		.eSign_submit_button {
			height: 50px;
			border-radius: 8px;
			width: 90%;
			font-family: 'Red Hat Display';
			font-weight: 500;
		}
	}
}

.eSign_modal_antd {
	width: 100%;
	height: 400px;

	.eSign_signature_canvas {
		border: 1px solid #eceaee;
		border-radius: 12px;
		overflow: hidden;
		margin: 1rem;
	}

	.eSign_buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;

		.eSign_buttons_submit {
			height: 50px;
			border-radius: 8px;
			width: 80%;
			margin: auto;
			font-family: 'Red Hat Display';
			font-weight: 500;
		}
	}
}
// *****************************

// FOR STRIPE CREDIT CARD
.back_button {
	position: relative;
	top: 50px;
	left: 50px;
	font-weight: 600;
	font-size: 24px;
	display: flex;
	align-items: center;

	button {
		height: auto;
	}
}

.stripe_heading {
	display: flex;
	justify-content: center;

	span {
		text-align: center;
		width: 100%;
		font-weight: 700;
		font-size: 20px;
	}
}

.card_details {
	margin: 0 2rem;
}

.label label {
	color: #6b7c93;
	font-weight: 300;
	letter-spacing: 0.025em;
	width: 100%;
	max-width: 500px;
	min-width: 280px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}

.form {
	border: 1px solid #eceaee;
	border-radius: 12px;
	padding: 1.5rem;
	margin: 3rem auto;
	height: auto;
	font-weight: 600;
	font-size: 16px;
	max-width: 550px;
}

.name {
	display: block;
	margin: 10px 0 20px 0;
	width: 100%;
	padding: 10px 14px;
	font-size: 1em;
	font-family: 'Source Code Pro', monospace;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border: 0;
	outline: 0;
	border-radius: 4px;
	background: white;
}

:global {
	.StripeElement {
		display: block;
		margin: 10px 0 20px 0;
		width: 100%;
		padding: 10px 14px;
		font-size: 1em;
		font-family: 'Source Code Pro', monospace;
		box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
		border: 0;
		outline: 0;
		border-radius: 4px;
		background: white;
	}

	input::placeholder {
		color: #aab7c4;
	}

	input:focus,
	.StripeElement--focus {
		box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
		-webkit-transition: all 150ms ease;
		transition: all 150ms ease;
	}
}
