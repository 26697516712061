.getty-thumbnail
    width: 70px
    height: 70px
    border-radius: 20%
    position: relative
    overflow: hidden
    display: inline-block

    & img
        min-width: 100%
        min-height: 100%
        width: auto
        height: auto
        position: absolute
        left: 50%
        top: 50%
        -webkit-transform: translate(-50%, -50%)
        -moz-transform: translate(-50%, -50%)
        -ms-transform: translate(-50%, -50%)
        transform: translate(-50%, -50%)

.getty-thumb-tag
    position: relative
    color: white
    background: #262626
    top: 65%
    left: 65%
    padding: 5px
    font-size: 12px
    opacity: 80%
    border-radius: 8px

.getty-thumb-meta-box
    display: inline-block
    position: relative
    left: 25px
    bottom: 25px

