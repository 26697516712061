.buttons{
    display: grid;
     grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) ;
     gap:10px;
     margin-top: 10px;
}

.back-button {
    height: 48px;
    font-size: 16px;
    width: 100%;
    background: rgb(255 255 255);
    color: deepskyblue;
    border-color: deepskyblue;
}

 