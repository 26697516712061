.en-project-big-card
    width: 55%
    min-height: 370px
    border-radius: 12px
    float: left
    margin: 10px

.en-project-small-card
    width: 39%
    min-height: 370px
    border-radius: 12px
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751)
    margin: 10px
    float: left
