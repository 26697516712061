.order-details-card{
    margin-top: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751);

}
.search-box{
    width: 340px;
    border-radius: 8px;
}

.radio-button{ 
    width: 116px;
    text-align: center;
}

.left{
    border-radius: 8px 0px 0px 8px !important;
}

.right{
    border-radius: 0px 8px 8px 0px !important;
}

.body{
    padding-top: 20px;
}