.my-plans-header-banner
    border-radius: 10px
    min-height: 147px
    color: white
    background-image: url('../../Assets/Images/aeropath_banner.png')
    background-repeat: no-repeat
    background-size: cover

.my-plans-empty-content-card
    border-radius: 10px
    margin-top: 20px
    text-align: center
    padding: 50px

.my-plans-content-card
    border-radius: 10px
    margin-top: 20px

.my-plans-plan-card
    border-radius: 12px

.my-plans-plan-date
    font-size: 13px
    border-radius: 0px 0px 12px 12px
    background: #e8e8e8
    width: 186px
    margin-left: -25px
    margin-top: 7px
    text-align: center
    height: 32px
    padding: 5px

.my-plans-plan-date-mobile
    font-size: 13px
    border-radius: 0px 0px 12px 12px
    background: #E5F7FF
    width: 150px
    margin-left: -25px
    margin-top: 7px
    text-align: center
    height: 32px
    padding: 5px

.my-plans-plan-date-popular
    font-size: 13px
    border-radius: 0px 0px 12px 12px
    background: #e8e8e8
    width: 182px
    margin-left: -24px
    margin-top: 3px
    text-align: center
    height: 32px
    padding: 5px

.my-plans-plan-date-popular-mobile
    font-size: 13px
    border-radius: 0px 0px 12px 12px
    background: #E5F7FF
    width: 146px
    margin-left: -24px
    margin-top: 3px
    text-align: center
    height: 32px
    padding: 5px

.my-plans-plan-wrapper
    margin: 10px
    display: inline-block
    cursor: pointer

.my-plans-pop-card
    width: 76px
    height: 16px
    background: #096DD9
    border-radius: 20px
    color: white
    font-size: 8px
    text-align: center
    padding: 2px
    position: relative
    top: 10px
    z-index: 10

.ant-modal-body
    border-radius: 10px

.my-plans-check-icon
    position: absolute
    top: 10px
    right: 10px
    color: #096DD9

.my-plans-plan-detail-card
    border-radius: 12px
    margin: 15px
