.upgrades-card
    border-radius: 4px
    margin: 10px
    width: 360px
    padding: 20px
    box-shadow: 0px 0px 10px rgba(138, 149, 158, 0.15)

.text-content
    text-align: center
    padding: 10px
    width: 100%
    margin: auto
    height: 250px

.img-content
    text-align: center
    width: 50%
    margin: auto
